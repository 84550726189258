import { delay, each, every, filter, find, go, sel, tap } from 'fxjs/es';
import { CateListConstantS } from '../../CateList/S/Constant/module/CateListConstantS.js';
import { addCvObjAlignmentToFcanvas } from '../../NewMaker/CvObjAlignment/F/Function/cv_obj_alignment.js';
import { makeFrontBlankHtml, makeMpMakerHtml } from './maker_tmpl.js';
import { makePrevProductMatcherEvent } from './prev_product_matcher.js';
import {
  createFcanvas,
  getFonts,
  getIndexOfAppropriateFaceInMaker,
  init_is_size_v,
  orderFaceBackgroundInMaker,
  rejectWrongCvTextImage,
  removeAllDesigns,
} from './util.js';
import { makeSelectFacesEvent, selectFaceInitA } from './select_faces.js';
import {
  addTdeisngsToFcanvasInMaker,
  initPrevProductFaces,
  initUploadAllPrintableFilesBtn,
} from './draw_product_faces.js';
import { getSizeFromCanvasInMaker } from './auto_phonecase_print_img.js';
import { makeFakeMpMakerSize } from './fake_mp_maker.js';
import {
  getBaseProductColorFacesInMaker,
  getBaseProductColorInMaker,
  getBaseProductFacesInMaker,
  getBaseProductInMaker,
  getCurrentBpcfsInMaker,
  getCurrentBpsId,
  getFcanvass,
  getMpMakerEl,
  getProductColorInMaker,
  getRealFcanvass,
  getSelfCanvasContainer,
  getSfFromBpcfByBpsIdInMaker,
} from './getSth.js';
import { canGoBlueLIne, initBlueLine } from './cv_blue_line.js';
import {
  marpplizerMode,
  setCanvasContainerSizeAndMpMakerHeight,
  whenResizeInMaker,
} from './f_canvas_setting.js';
import { makePrevCvBpcfAttr } from './attr.js';
import { marpplizerProxy, resetMainConditionAgreeText, startMarpplizer } from './Marpplizer/marpplizer.js';
import { initDesignListLayer, makeDesignListLayerEvent } from './Layer/fs.js';
import { makeBpcfAvgSize } from './canvas_size.js';
import { checkAllFcanvasDesignSize } from './check_image.js';
import { checkIsOverflowInCurrentFace } from './overflow.js';
import { baseProductChangeInit } from './Product/product_fs.js';
import {
  addCvTextImageInMakerA,
  full_cv_text_image,
  loadDefaultFonts,
  makeDataCmAndChangeCvTextResetBoxDataA,
} from './text.js';
import { makeEnterMarppleShopEvent } from '../../Marpple/PopUp/F/enter_marpple_shop1.js';
import { isCateItemPhoneCase, isCompositeStyle, isCylinderStyle } from './categorize.js';
import { resetBgWithoutCvMaskBySf } from './SizeFaces/fs.js';
import { dealWithVideoUrl } from './CvBpcfVideo/fs.js';
import { makeCvBpcfInMakerA } from './CvBpcf/fs.js';
import { makeDecorationPcEvent } from './DecorationIcon/event.js';
import { isShadeStyle, renderMakerPageShade } from './CvPreview/Shade/render.js';
import { getCurrentFcanvas, getCvDesigns } from './Fcanvas/cv_object.js';
import { makeCvPrintAreaInMaker } from './CvPrintArea/cv_object.js';
import { makeCylinderAreaInMaker } from './CvPreview/Cylinder/obj.js';
import { makeCvMockupInMaker } from './CvPreview/Composite/obj.js';
import { decodePNG16bits } from '../../Composite/Core/F/image_mapping.js';
import { $addClass, $hasClass, $qs, $removeClass, $setAttr, $setCss } from 'fxdom/es';
import { makeCvShadingInMakerA } from './CvShading/fs.js';
import { makeCvMask1InMakerA } from './CvMask1/fs.js';
import { makeCvMask2InMakerA } from './CvMask2/fs.js';
import { makeCvSafeAreaInMakerA } from './CvSafeArea/fs.js';
import { makeCvPrintAreaMaskInMaker } from './CvPrintAreaMask/fs.js';
import { NewMakerCvObjectCvSafetyArea } from '../../NewMaker/CvObject/CvSafetyArea/Function/module/NewMakerCvObjectCvSafetyArea.js';
import { NewMakerWeS } from '../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { NewMakerMenuF } from '../../NewMaker/Menu/F/Function/module/NewMakerMenuF.js';
import { NewMakerIconMenuF } from '../../NewMaker/IconMenu/F/Function/module/NewMakerIconMenuF.js';
import { NewMakerMobileBottomMenuF } from '../../NewMaker/MobileBottomMenu/F/Function/module/NewMakerMobileBottomMenuF.js';
import { addCvPreview, removeCvPreview } from './CvPreview/cv_object.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerCheckerF } from '../../NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { NewMakerLegacyF } from '../../NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { cvPrintItemEvent } from './CvPrintItem/cv_object.js';
import { SVGEditorProductDetailF } from '../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { getFontColors } from './CvTextImage/fs.js';
import { BpOptionF } from '../../BpOption/F/Function/module/BpOptionF.js';
import { BpOptionConstantS } from '../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerFitPrintWidthF } from '../../NewMaker/FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { NewMakerBaseProductsKeyCapConstantS } from '../../NewMaker/BaseProducts/KeyCap/S/Constant/module/NewMakerBaseProductsKeyCapConstantS.js';
import { BpOptionStampConstantS } from '../../BpOption/Stamp/S/Constant/module/BpOptionStampConstantS.js';
import { NewMakerCvObjectCvImageF } from '../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { makeProductColorInProductDetailHtmlMobile } from '../S/product.js';
import { BpOptionUploadTypeS } from '../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { setDataUploadTypeIsDesigned } from '../../NewMaker/Util/F/Function/makeDataIsDesigned.js';
import { NewMakerPropertyBpfF } from '../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { NewMakerPropertyBaseProductConstantS } from '../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { bizOptionMenus } from './Marpplizer/biz.js';
import { NewMakerUtilS } from '../../NewMaker/Util/S/Function/module/NewMakerUtilS.js';
import { BpOptionUploadTypeF } from '../../BpOption/UploadType/F/Function/module/BpOptionUploadTypeF.js';
import { OMPCoreUtilF } from '../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerPropertyBaseProductS } from '../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { NewMakerCanvasEditorF } from '../../NewMaker/CanvasEditor/F/Function/module/NewMakerCanvasEditorF.js';
import { MakerDebugF } from '../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { getSelectedBpOptionIds } from '../../BpOption/S/Function/initSelectedOptionGroup.js';
import { NewMakerCallConditionPopupF } from '../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { NewMakerConditionAgreeTextF } from '../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { ConfigSentryF } from '../../Config/Sentry/F/Function/module/ConfigSentryF.js';

export const makeFabricCanvasSettingA = async function (f_canvas, bpcf, size_face) {
  if (bpcf._.base_product_face.preview) {
    f_canvas.preview = bpcf._.base_product_face.preview;
    f_canvas.preview.on = false;
    if (isCompositeStyle(f_canvas)) {
      f_canvas.preview.composite.m = await decodePNG16bits(f_canvas.preview.composite.logo);
    }
    const bpc = getBaseProductColorInMaker();
    const bp = getBaseProductInMaker();
    if (bp?.maker_type === BpOptionStampConstantS.STAMP_EDITOR) {
      const stamps = BpOptionStampConstantS.stamps['s' + bp.id];
      const texture = stamps?.find(({ color_code }) => color_code === bpc?.color_code);
      if (texture) {
        f_canvas.preview.shade.hex = texture.color_code;
        f_canvas.preview.shade.texture_url = texture.texture_url;
        f_canvas.preview.shade.original_texture_url = texture.texture_url;
      }
    }
  } else {
    delete f_canvas.preview;
  }
  const bpf = bpcf._.base_product_face;
  _p.extend(f_canvas, {
    bpf_id: bpf.id,
    face_name: bpf.name,
    face_name_en: bpf.name_en || bpf.name,
    start_name: bpf.start_name,
  });
  const selected_bp_option_ids = getSelectedBpOptionIds(getProductColorInMaker());
  NewMakerPropertyBpfF.bpOptionLayers.setBpf({ bpf, selected_bp_option_ids });
  await renderIsNotDesignsToCanvasA(bpcf, bpf, size_face, f_canvas);
};

export async function initAfterPlacingProductFaces(is_first_setting, is_not_newly_placed) {
  G.mp.maker.all_reset_data_cm();
  G.mp.maker.editing_canvas(getIndexOfAppropriateFaceInMaker(), true);
  G.mp.maker.bpcf_size = makeBpcfAvgSize();
  await dealWithVideoUrl();
  init_is_size_v();
  checkAllFcanvasDesignSize();
  checkIsOverflowInCurrentFace();
  G.mp.maker.state.start();
  await G.mp.maker.modified();

  G.mp.maker.editing_canvas().renderAll();
  if (!UtilF.isLegacyMobile() && !$1('html#dream_factory')) baseProductChangeInit();
  const fcanvas = getCurrentFcanvas();
  const mp_maker = getMpMakerEl(fcanvas);
  if (!is_first_setting) {
    if (isShadeStyle(fcanvas)) {
      await go(
        getRealFcanvass(),
        each((fcanvas) => renderMakerPageShade(fcanvas, getCvDesigns(fcanvas._objects))),
      );
    } else {
      await go(
        getRealFcanvass(),
        each(async (fcanvas) => {
          const cv_objs = getCvDesigns(fcanvas._objects);
          await G.mp.maker.cv_objects_deep_each(cv_objs, async (cv_o) => {
            if (cv_o._data.cv_type === 'cv_text_image') {
              await full_cv_text_image(function () {}, cv_o, true);
            }
            if (cv_o._data.cv_type === 'cv_image' && cv_o._data.is_black_and_white_contrast) {
              delete cv_o._data.is_black_and_white_contrast;
            }
          });
        }),
      );
    }
  }
  const base_product = getBaseProductInMaker();
  if (!is_not_newly_placed) {
    NewMakerCanvasEditorF.service.init(base_product);
  }
  if (mp_maker) {
    const base_products_bp_option_groups = base_product._.base_products_bp_option_groups;
    if (BpOptionUploadTypeS.UPLOAD_TYPE.hasUploadType(base_products_bp_option_groups)) {
      const product = getProductColorInMaker();

      mp_maker.dataset.maker_upload_type = BpOptionUploadTypeS.UPLOAD_TYPE.getUploadType(
        base_products_bp_option_groups,
        product._.selected_option_group?.bp_option_ids,
      );
      setDataUploadTypeIsDesigned();
    }
    mp_maker.dataset.is_digital_product = String(
      base_product.cate_list_id == CateListConstantS.DIGITAL_LIST_ID,
    );
    mp_maker.dataset.is_vector = String(VectorEditorConstantS.EDITOR_TYPES.includes(base_product.maker_type));
    // mp_maker.dataset.is_pdf_upload_type_designed =
    //   !!BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.getPdfPrintingFile(getProductColorInMaker());
    setDataUploadTypeIsDesigned();
    mp_maker.dataset.maker_type = base_product.maker_type;
    mp_maker.dataset.is_embroidery_app = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.isEmbroideryApp(
      NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
      base_product.id,
    );
    mp_maker.dataset.is_bad_computing_power = !!(
      (UtilF.isLegacyMobile() && [BpOptionConstantS.MASKING_TAPE_EDITOR].includes(base_product.maker_type)) ||
      G.mp.maker.is_ie ||
      UtilF.is_ios()
    );
    // if (mp_maker.dataset.is_embroidery_app === 'true') {
    //   mp_maker.dataset.is_bad_computing_power = 'false';
    // }
    mp_maker.dataset.need_fit_to_print_width = NewMakerFitPrintWidthF.needFitToPrintWidth(base_product);
    mp_maker.dataset.is_not_image_task =
      !NewMakerBaseProductsKeyCapConstantS.bp_ids.includes(base_product.id) &&
      (base_product.is_not_image_task ||
        base_product.has_template ||
        /* TODO 카테고리 하드코딩 */
        base_product.cate_list_id === CateListConstantS.PHONE_ACC_LIST_ID ||
        !!$qs('#dream_factory'));
    const input_file = $1('.decoration_menu_for_pc .add_img input[type="file"]');
    if (input_file) {
      const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
      const is_not_image_task = mp_maker.dataset.is_not_image_task === 'true';
      const vector = 'image/jpeg,image/png,image/svg+xml';
      const not_image_task = 'image/jpeg,image/png';
      input_file.accept = VectorEditorConstantS.EDITOR_TYPES.includes(maker_type)
        ? vector
        : is_not_image_task == true
        ? not_image_task
        : 'image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript';
    }
    each((fcanvas) => (fcanvas.fcanvas_data = {}))(getFcanvass());
    each(
      (fcanvas) =>
        (fcanvas.fcanvas_data = {
          maker_type: base_product.maker_type,
        }),
    )(getRealFcanvass());
    mp_maker.dataset.is_carved_phonecase_product = String(
      NewMakerProductStyleS.isCarvedPhoneCase(base_product),
    );
    mp_maker.dataset.is_no_request_bg_removal = String(
      !!NewMakerProductStyleS.isNoRequestBgRemoval(base_product),
    );

    mp_maker.dataset.feature__mobile_maker_size_ratio = base_product.maker_features?.mobile_maker_size_ratio;

    go(mp_maker, $removeClass('unable_undo_redo'));
    if (base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.UNABLE_UNDO_REDO]) {
      go(mp_maker, $addClass('unable_undo_redo'));
    }
    go(mp_maker, $setAttr({ is_we_base_products: NewMakerWeS.isWeBaseProduct(base_product.id) }));
    if (mp_maker.dataset.is_carved_phonecase_product === 'true' && !getCvDesigns(fcanvas._objects)?.length) {
      await addCvTextImageInMakerA(true);
    }
  }
  setCanvasContainerSizeAndMpMakerHeight();
  bizOptionMenus.init();
  await marpplizerProxy(G.mp.maker.active());
  if (NewMakerProductStyleS.isNoRequestBgRemoval(base_product)) {
    NewMakerCvObjectCvImageF.makeRequestBgRemovalFalse();
  }
  if (UtilF.isLegacyMobile()) {
    if ($qs('html.maker_editor_mode')) {
      await NewMakerCallConditionPopupF.reset();
    }
    // await NewMakerCallConditionPopupF.reset();
  } else {
    await NewMakerCallConditionPopupF.reset();
    resetMainConditionAgreeText();
  }
  NewMakerConditionAgreeTextF.checkAddCartDisabled();
}

function get_short_name_size(product_color) {
  product_color = product_color || box.sel('maker->product_color');
  const bps_id = _p.v(product_color, 'base_product_size_id');
  return _p.find(_p.v(product_color, '_.base_product._.base_product_sizes'), (bps) => bps.id === bps_id);
}

function getBpPossibleSize(bp, size_name) {
  return (
    _find(bp._.base_product_sizes, (size) => size['short_name' + _en] === size_name) ||
    _find(bp._.base_product_sizes, _p.v('is_thumb')) ||
    bp._.base_product_sizes[0]
  );
}

export function changeProductColorByBps(bps) {
  getProductColorInMaker().base_product_size_id = bps.id;
}

export function renderFacesByCurrentBpcfsOnlySizeInMakerA() {
  return go(
    getBaseProductColorFacesInMaker(),
    _p.each(async function (bpcf, i) {
      const f_canvas = box.sel('maker->canvass->' + i);
      const size_face = getSfFromBpcfByBpsIdInMaker(bpcf, getCurrentBpsId());
      await delay(200)(() => {});
      await makeFabricCanvasSettingA(f_canvas, bpcf, size_face);
    }),
    function () {
      G.mp.maker.bpcf_size = makeBpcfAvgSize();
    },
    async function () {
      checkIsOverflowInCurrentFace();
      await marpplizerProxy();
      G.mp.maker.center_mode.init();
    },
  );
}

export function changeProductColorByBpc(bpc) {
  const product_color = getProductColorInMaker();
  product_color.base_product_color_id = bpc.id;
  product_color._.base_product_color = bpc;
}

export function renderFacesByCurrentBpcfsInMakerA() {
  return _p.go(
    getCurrentBpcfsInMaker(),
    _p.map((bpcf, i) => [bpcf, i]),
    _p.sortBy(function ([_, i]) {
      return i !== G.mp.maker.editing_canvas_idx();
    }),
    each(renderFcanvasByBpcfA),
    async function () {
      G.mp.maker.reset_box_data();
      G.mp.maker.bpcf_size = makeBpcfAvgSize();
    },
  );
}

export function changeProductColorByBpWithItsPossibleSize(bp) {
  const size_name = sel('short_name' + _en, get_short_name_size());
  const bpc = find(sel('is_thumb'))(bp._.base_product_colors) || bp._.base_product_colors[0];
  const bps = getBpPossibleSize(bp, size_name);
  const product_color = _p.defaults(
    {
      base_product_id: bp.id,
      base_product_color_id: bpc.id,
      base_product_size_id: bps.id,
      product_faces2: {},
      id: null,
      product_id: null,
      svg_product_id: null,
      selected_option_group_id: null,
    },
    _p.omit(box.sel('maker->product_color'), ['_']),
  );
  product_color._ = {
    base_product: bp,
    base_product_color: bpc,
  };
  box.set('maker->product_color', product_color);
}

export async function renderNewFacesByCurrentBpcfsInMakerA(is_clear) {
  const bpcfs = getCurrentBpcfsInMaker();
  $.attr($1('.mp_maker'), {
    faces_length: bpcfs.length,
  });
  go(
    $1('#maker_frame'),
    $.find1('.front_blank'),
    (front_blank_el) => (front_blank_el.outerHTML = makeFrontBlankHtml(bpcfs)),
  );

  BpOptionF.setDefaultSelectedOptionGroup(getProductColorInMaker());

  return _p.go(
    _p.map(bpcfs, function (bpcf, i) {
      return [bpcf, i];
    }),
    _p.each(renderFcanvasByBpcfA),
    async function () {
      removeAllDesigns();
      if (!is_clear) await addDesignsByProductFaces();
      await makeDataCmAndChangeCvTextResetBoxDataA();
    },
  );
}

async function addDesignsByProductFaces() {
  await _p.go(
    box.sel('maker->product_color').product_faces2.value,
    _p.each(async function (pf) {
      const designs = pf.designs;
      const fcanvas = find((fcanvas) => {
        return fcanvas.bpf_id === pf.bpf_id;
      }, getRealFcanvass());
      await addTdeisngsToFcanvasInMaker(designs, fcanvas, 1);
      _p.each(G.mp.maker.designs(fcanvas), function (design) {
        G.mp.maker.nscreen(design);
      });
      fcanvas.renderAll();
    }),
  );
}

export async function renderFcanvasByBpcfA([bpcf, i]) {
  const f_canvas = box.sel('maker->canvass')[i];
  const size_face = getSfFromBpcfByBpsIdInMaker(bpcf, getCurrentBpsId());
  const need_opacity = i === G.mp.maker.editing_canvas_idx();
  const container = getSelfCanvasContainer(f_canvas);
  if (need_opacity) {
    $.css(container, { opacity: 0 });
  }
  if (f_canvas.preview) {
    /*
     * preview 제품들 리뷰 필요
     * */
    removeCvPreview(f_canvas, true, true);
  }
  await makeFabricCanvasSettingA(f_canvas, bpcf, size_face);
  if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(f_canvas)) {
    const bpc = getBaseProductColorInMaker();
    await NewMakerPropertyBpfF.bpcColorCode2Render.applyAll([f_canvas], bpc);
    G.mp.maker.reset_box_data();
  }
  await addCvPreview(f_canvas, true);
  if (need_opacity) {
    await anime({
      targets: [container],
      opacity: 1,
      duration: 200,
      easing: 'linear',
    }).finished;
  }
}

function makeCvBpcfBBInMakerA(canvas, bpcf, sf) {
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpcf.url), function (img) {
      img.set(makePrevCvBpcfAttr());
      canvas.add(img);
      resetBgWithoutCvMaskBySf(img, sf);
      canvas.renderAll();
      resolve();
    });
  });
}

function logicForPrevCvBpcfAfterAddDesignsToCanvas(canvas, pf, sf) {
  return go(makeCvBpcfBBInMakerA(canvas, { url: pf.cv_bpcf.src }, sf), function () {
    const cv_bpcf_obj = G.mp.maker.cv_bpcf(canvas);
    function f(canvas, cv_obj) {
      let objs_to_hide;
      return _p.go(
        canvas._objects,
        _p.reject((obj) => obj === cv_obj || obj.visible === false),
        _p.each((obj) => (obj.visible = false)),
        function (objs) {
          objs_to_hide = objs;
          canvas.renderAll();
        },
        function () {
          return getSizeFromCanvasInMaker(canvas.lowerCanvasEl);
        },
        _p.tap(function () {
          _p.each(objs_to_hide, (obj) => (obj.visible = true));
          canvas.renderAll();
        }),
      );
    }
    const prev_cv_bpcf_obj = G.mp.maker.prev_cv_bpcf(canvas);
    const cv_bpcf_obj_size = f(canvas, cv_bpcf_obj);
    const prev_cv_bpcf_obj_size = f(canvas, prev_cv_bpcf_obj);

    const diff_left = cv_bpcf_obj_size.left - prev_cv_bpcf_obj_size.left;
    const diff_top = cv_bpcf_obj_size.top - prev_cv_bpcf_obj_size.top;
    const ratio_width = cv_bpcf_obj_size.width / prev_cv_bpcf_obj_size.width;
    const ratio_height = cv_bpcf_obj_size.height / prev_cv_bpcf_obj_size.height;
    const ratio = _p.max([ratio_width, ratio_height], function (a) {
      return Math.abs(1 - a);
    });
    _p.go(
      G.mp.maker.designs(canvas),
      _p.each(function (obj) {
        obj
          .set({
            left: obj.left + diff_left,
            top: obj.top + diff_top,
          })
          .setCoords();
        const top = obj.top - cv_bpcf_obj_size.top;
        const top2 = top * ratio_height;
        const left = obj.left - cv_bpcf_obj_size.left;
        const left2 = left * ratio_width;
        const width = obj.scaleX * obj.width;

        const width2 = width * ratio;
        const scale = width2 / obj.width;

        obj
          .set({
            left: cv_bpcf_obj_size.left + left2,
            top: cv_bpcf_obj_size.top + top2,
          })
          .setCoords();
        obj.scale(scale);
      }),
    );
    canvas.remove(prev_cv_bpcf_obj);
  });
}

function getBpsIdFromUpC() {
  return box.sel('maker->up_c->base_product_size_id');
}

export const addMakerPageClass = () => {
  $.add_class([$1('html'), $1('body')], 'maker_page maker_canvas_editor_v1');
};

export const removeMakerPageClass = () => {
  $.remove_class([$1('html'), $1('body')], 'maker_page maker_canvas_editor_v1');
};

// const galaxyAndroidEvent = function () {
//   // 갤럭시 키보드 가려지는 이벤트
//   if (G.mp.maker.is_android_chrome) {
//     $.off(window, 'resize.maker');
//     $.on(window, 'resize.maker', function () {
//       if (
//         $1('html.maker_editor_mode') &&
//         $1('html.maker_showing_keyboard') &&
//         $.height(window) >= G.mp.maker.window_height * 0.8
//       ) {
//         const active = G.mp.maker.active();
//         if (!active) return;
//         active.exitEditing();
//       }
//     });
//   }
// };

export const eventMpMaker = () => {
  G.mp.maker.create_el_maker($1('.mp_maker'));
  makeDecorationPcEvent($1('.mp_maker'));
  G.mp.maker.tooltip_canvas.event($1('.mp_maker')); // mobile 길게 눌렀을 때
  NewMakerMenuF.event($1('.mp_maker')); // mobile
  NewMakerMobileBottomMenuF.event($1('.mp_maker')); // mobile
  NewMakerIconMenuF.decorationEvent($1('.mp_maker')); // mobile
  makeDesignListLayerEvent($1('.mp_maker')); // 좌측 레이어
  makePrevProductMatcherEvent($1('.mp_maker')); // ?? 확인 못함
  makeSelectFacesEvent($1('.mp_maker')); // face 선택
  makeEnterMarppleShopEvent($1('.mp_maker')); // marpple shop으로 가는 버튼

  initDesignListLayer($1('.mp_maker'));
  G.mp.maker.image_upload_desc_event();
  G.mp.maker.loc_opt_desc_event();
  // galaxyAndroidEvent();
  go(
    $qs('.mp_maker'),
    (el) => NewMakerConditionAgreeTextF.changeEvent(el, '.condition_agree_text'),
    (el) => NewMakerConditionAgreeTextF.clickEvent(el, '.condition_agree_text'),
  );
  _go(
    document,
    $.on('click.maker', async function (e) {
      if (!$.closest(e.target, '#maker_frame')) return;
      if ($.closest(e.target, '.menu_down_list')) return;
      if ($1('#tooltip_canvas.is_show')) {
        if (!$.closest(e.target, '#tooltip_canvas')) G.mp.maker.tooltip_canvas.close();
      }
      if (!$.closest(e.target, '.decoration_menu') && $1('#maker_frame .decoration_menu.decoration_mode'))
        await NewMakerIconMenuF.decorationOff();
      if (!$.closest(e.target, '.maker_menu .more_btn')) {
        NewMakerMenuF.hide();
      }

      if (!UtilF.isLegacyMobile() && G.mp.maker.active() && e.target === $1('#maker_frame .board')) {
        G.mp.maker.unselect_all();
      }

      if (
        !UtilF.isLegacyMobile() &&
        $1('#cv_background.activity') &&
        $hasClass('upper-canvas')(e.target) &&
        !G.mp.maker.active()
      ) {
        await marpplizerProxy();
      }
    }),
  );
  $.on(
    window,
    'resize.maker',
    _p.throttle(function () {
      if (!UtilF.isLegacyMobile()) whenResizeInMaker();
    }, 300),
  );
  if (UtilF.isLegacyMobile()) {
    $.off($1('.mp_maker'), 'touchend.prevent_touch_mp_maker');
    $.off($1('.mp_maker'), 'touchstart.prevent_touch_mp_maker');
    const need_prevent_default = true;
    // $.on($1('.mp_maker'), 'touchstart.prevent_touch_mp_maker', function (e) {
    //   need_prevent_default = !$qs('html.prev_product_matcher');
    // });
    // $.on($1('.mp_maker'), 'touchmove.prevent_touch_mp_maker', function (e) {
    //   if (need_prevent_default) e.preventDefault && e.preventDefault();
    // });
    const eventDocumentTouch = function (e) {
      if ($qs('.don_dialog_msg')) {
        e.preventDefault && e.preventDefault();
      }
    };
    const eventDocumentTouchStart = function (e) {
      if (!$qs('html.maker_page')) {
        document.removeEventListener('touchmove', eventDocumentTouch);
        document.removeEventListener('touchstart', eventDocumentTouchStart);
      }
    };
    // document.removeEventListener('touchmove', eventDocumentTouch);
    // document.removeEventListener('touchstart', eventDocumentTouchStart);
    // document.addEventListener('touchmove', eventDocumentTouch, { passive: false });
    // document.addEventListener('touchstart', eventDocumentTouchStart);
  } else {
    // $.off($1('.canvass'), 'touchmove.mp_maker');
    // $.on($1('.canvass'), 'touchmove.mp_maker', function (e) {
    //   e.preventDefault && e.preventDefault();
    // });
  }
};
export const initMpMaker = async (target_el, is_printable = false) => {
  try {
    $.don_loader_start();
    $.add_class([$1('html'), $1('body')], 'mp_maker_not_ready');
    addMakerPageClass();
    G.mp.maker.initWindowSizeInMaker();
    const product_color = box.sel('maker->product_color');
    const my_image_count = box.sel('user_counts->my_image');
    await go(
      makeMpMakerHtml(product_color, my_image_count, is_printable),
      $.el,
      $.prepend_to(target_el),
      tap(async function () {
        /*event*/
        eventMpMaker();
        /*event*/

        if (G.collabo_type !== '') {
          marpplizerMode('basic');
        }
        $setCss({ opacity: 0 })($qs('.mp_maker .canvass'));
        setCanvasContainerSizeAndMpMakerHeight();
        await initAllForMpMaker(true);
        const maker_frame_el = $qs('#maker_frame');
        if (maker_frame_el?.dataset?.is_vector === 'true') {
          const bp_option_groups_el = $qs('.bp_option_groups');
          await SVGEditorProductDetailF.vectorEditorPreviewInit(bp_option_groups_el);
        }
        if (!NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)) {
          $setCss({ opacity: 1 })($qs('.mp_maker .canvass'));
        }
        $.remove_class([$1('html'), $1('body')], 'mp_maker_not_ready');
        $.don_loader_end();
        getFontColors();
        getFonts();
        loadDefaultFonts();
        BpOptionUploadTypeF.uploadTypeDirect.activeTippy();
        const pdf_printing_file = BpOptionUploadTypeS.UPLOAD_TYPE_DIRECT.getPdfPrintingFile(product_color);
        if (pdf_printing_file && OMPCoreUtilS.isExpired(pdf_printing_file)) {
          await OMPCoreUtilF.alertPdfFileExpired({ product: product_color });
          $.don_loader_start();
          NewMakerUtilF.reload.baseProduct();
        }
      }),
    );
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(getProductColorInMaker(), e, MakerDebugConstantS.Category.MakerInit);
  }
};

export function unsetFcanvass() {
  box.set('maker->editing_canvas_idx', null);
  box.set('maker->editing_canvas', null);
  _each(box.sel('maker->canvass'), function (canvas) {
    canvas.dispose();
  });
  box.set('maker->canvass', []);
}

export function initFcanvass(el_canvass) {
  box.set('maker->editing_canvas_idx', null);
  box.set('maker->editing_canvas', null);
  _each(box.sel('maker->canvass'), function (canvas) {
    canvas.dispose();
  });
  box.set('maker->canvass', []);
  _p.go(
    el_canvass,
    _p.each((el_canvas) => {
      box.push('maker->canvass', createFcanvas(el_canvas));
    }),
  );
}

function getProductFaceFromFcanvas(product_faces, canvas) {
  return _p.find(product_faces, function (pf) {
    return pf.bpf_id == canvas.bpf_id;
  });
}

export const addDesignsToCanvas = function (pf, f_canvas) {
  if (!pf) return;
  return _p.go(
    pf.cv_background ? [pf.cv_background].concat(pf.designs) : pf.designs,
    G.mp.maker.reject_ai,
    rejectWrongCvTextImage,
    _p.cmap(function (data) {
      return _p.go(G.mp.maker.from_cv_attrs_for_maker(data), G.mp.maker.nscreen);
    }),
    _p.reject((design) => design._data.error),
    tap(
      filter((cv_obj) => cv_obj?._data?.is_fitted_to_print_width),
      each(NewMakerFitPrintWidthF.activateDataFitToPrintWidth),
    ),
    _p.each(function (design) {
      f_canvas.add(design);
    }),
  );
};

function isNeedPrevCvBpcfLogic(product_face, bpcf) {
  if (!product_face) return false;
  if (!isCateItemPhoneCase()) return false;
  if (G.to_original(product_face.cv_bpcf.src) === G.to_original(bpcf.url)) return false;
  return true;
}

const localStorageProxy = new Proxy(localStorage, {
  get: function (target, key) {
    const value = target.getItem(key);
    // target.removeItem(key); // 해당 키를 바로 삭제
    return value;
  },
  set: function (target, key, value) {
    target.setItem(key, value);
    return true;
  },
});

export function setTempLocalStorageProduct(product_color) {
  const temp_product = JSON.stringify({
    product_color,
    ...(!box.sel('is_user') ? { _temp_local_expired_at: new Date().getTime() } : {}),
  });
  // console.log(_product_color.replace(/data:image\/[a-zA-Z]+;base64,[^\s"]+/g, ''));
  localStorageProxy.temp_product = temp_product;
}
export function getTempLocalStorageProduct() {
  return JSON.parse(localStorageProxy.temp_product || null);
}

export function deleteTempLocalStorageProduct() {
  localStorage.removeItem('temp_product');
}

export function setTempLocalStorageIsUser(is_user) {
  localStorageProxy.is_user = JSON.stringify(is_user);
}
export function getTempLocalStorageIsUser() {
  return JSON.parse(localStorageProxy.is_user || null);
}

export function deleteTempLocalStorageIsUser() {
  localStorage.removeItem('is_user');
}

export async function loadTempLocalStorage() {
  try {
    const temp_product = getTempLocalStorageProduct();
    if (!temp_product?.product_color) return;
    const is_user = getTempLocalStorageIsUser();
    if (!is_user && !temp_product._temp_local_expired_at) return;
    const { product_color, _temp_local_expired_at } = temp_product;
    removeAllTempLocalStorage();
    const url = new URL(location.href);
    const is_from_login = url.searchParams.get('login_redirect') === 'true';
    if (is_from_login) {
      url.searchParams.delete('login_redirect');
      history.replaceState(null, '', window.location.pathname + url.search + url.hash);
    }
    // const MAKER_TYPE = box.sel('maker->product_color->_->base_product->maker_type');
    const current_base_product_id = box.sel('maker->product_color->base_product_id');
    const same_product_by_id = current_base_product_id === product_color.base_product_id;
    const is_allowed =
      is_user?.id === box.sel('is_user->id') ||
      (_temp_local_expired_at ? new Date().getTime() - _temp_local_expired_at <= 1000 * 60 * 10 : false);
    if (
      // ['DEFAULT', 'MASKING_TAPE'].includes(MAKER_TYPE) &&
      product_color.product_faces2?.value?.length &&
      same_product_by_id &&
      is_allowed
    ) {
      const is_empty_temp_design = go(
        product_color.product_faces2.value,
        every(({ designs }) => !designs.length),
      );

      if (is_empty_temp_design) return;
      const is_load = is_from_login
        ? true
        : await $.confirm(T('maker_error_alert::임시 저장된 디자인을 불러오겠습니까?'));

      if (is_load) {
        box.set('maker->product_color', product_color);
        const product_detail_el = $1('#ProductColorInProductDetail');
        if (product_detail_el) {
          product_detail_el.outerHTML = makeProductColorInProductDetailHtmlMobile(
            box.sel('maker->product_color'),
            box.sel('review_list->review_total'),
            box.sel('shared_product'),
          );
        }
      }
    }
  } catch (e) {
    ConfigSentryF.error(e);
  }
}

const isQuotaExceededError = (err) => {
  return (
    err instanceof DOMException &&
    (err.name === 'QuotaExceededError' || err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  );
};

export const setTempLocalStorage = () => {
  try {
    const MAKER_TYPE = box.sel('maker->product_color->_->base_product->maker_type');
    const is_user = box.sel('is_user');

    if (['DEFAULT', 'MASKING_TAPE'].includes(MAKER_TYPE)) {
      const product_color = box.sel('maker->product_color');
      setTempLocalStorageIsUser(is_user);
      setTempLocalStorageProduct(product_color);
    }
  } catch (err) {
    if (isQuotaExceededError(err)) {
      // 용량 초과
    }
  }
};

export function removeAllTempLocalStorage() {
  deleteTempLocalStorageIsUser();
  deleteTempLocalStorageProduct();
}

function getProductFaces2() {
  return _p.v(box().maker.product_color.product_faces2, 'value');
}

export async function makeFcanvasBasic(el_canvass, is_maker_page, is_change_bp, pc_name) {
  initFcanvass(el_canvass);
  const fcanvass = getFcanvass();
  const has_not_pc_name = !box.sel('maker->product_color->name');

  if (!NewMakerUtilS.hasDesignsInFaces(getProductColorInMaker())) {
    const urlParams = new URLSearchParams(window.location.search);
    const isRedirectFromLogin = urlParams.get('is_redirect_from_login') === 'true';
    if (!isRedirectFromLogin) {
      await loadTempLocalStorage();
    }
  }

  const base_product = getBaseProductInMaker();
  return _p.go(
    getBaseProductColorFacesInMaker(),
    (arr) => _p.first(arr, el_canvass.length),
    _p.map(function (bpcf, i) {
      return [bpcf, fcanvass[i]];
    }),
    _p.cmap(async function ([bpcf, f_canvas]) {
      const size_face =
        getSfFromBpcfByBpsIdInMaker(bpcf, getBpsIdFromUpC()) ||
        getSfFromBpcfByBpsIdInMaker(bpcf, getCurrentBpsId()) ||
        _p.first(bpcf._.base_product_face.size_faces);
      await makeFabricCanvasSettingA(f_canvas, bpcf, size_face);
      return _p.go(
        getProductFaceFromFcanvas(getProductFaces2(), f_canvas),
        function (pc_product_face) {
          if (!pc_product_face) return;
          if (
            has_not_pc_name &&
            (!!$qs('#dream_factory') || G.collabo_type === '') &&
            base_product.maker_type === 'DEFAULT' &&
            is_maker_page &&
            !is_change_bp &&
            G.to_original(pc_product_face.cv_bpcf.src) !== G.to_original(bpcf.url)
          )
            $.alert(
              T(
                'maker::Message::The product image has been changed. Please check out the positions of your designs.',
              ),
            );
          return _p.go(addDesignsToCanvas(pc_product_face, f_canvas), function () {
            if (isNeedPrevCvBpcfLogic(pc_product_face, bpcf)) {
              if ($1('#dream_factory')) $.add_class($1('.mp_maker'), 'need_to_check_prev_cv_bpcf');
              return logicForPrevCvBpcfAfterAddDesignsToCanvas(f_canvas, pc_product_face, size_face);
            }
          });
        },
        function () {
          orderFaceBackgroundInMaker(f_canvas);
        },
      );
    }),
  );
}

export function initAllForMpMaker(is_maker_page) {
  if (!UtilF.isLegacyMobile()) startMarpplizer();
  return _p.go(
    makeFcanvasBasic($('#maker .board .canvass canvas'), is_maker_page),
    () =>
      _p.each(getFcanvass(), (f_canvas) => {
        G.mp.maker.canvas_event(f_canvas);
        // if (UtilF.isLegacyMobile()) NewMakerTopUpperCanvasF.init(f_canvas);
      }),
    () =>
      _p.each(getFcanvass(), (f_canvas) => renderMakerPageShade(f_canvas, getCvDesigns(f_canvas._objects))),
    /* 초기 init makeFcanvasBasic */
    () => initAfterPlacingProductFaces(true),

    _p.tap(async function () {
      if (
        [VectorEditorConstantS.STICKER_EDITOR, BpOptionConstantS.MASKING_TAPE_EDITOR].includes(
          getBaseProductInMaker().maker_type,
        )
      ) {
        await BpOptionF.afterSelection({ bp_option_groups_el: $qs('.bp_option_groups') });
      }
      if (!UtilF.isLegacyMobile()) {
        return _p.go(selectFaceInitA(), function () {
          $.trigger($1('html'), 'maker_load');

          if (canGoBlueLIne()) initBlueLine();
          if ($1('html#dream_factory')) {
            if (
              getBaseProductFacesInMaker()[0].size_faces[0].items &&
              getBaseProductFacesInMaker()[0].size_faces[0].items[0]?.px?.width
            )
              cvPrintItemEvent();
          }
          if ($1('html#dream_factory') && box.sel('user_made_product_color')) {
            const pf = box.sel('user_made_product_color->product_faces2->value');
            initUploadAllPrintableFilesBtn();
            initPrevProductFaces(pf);
            $.append(
              $1('html#dream_factory ul.decoration_menu_for_pc'),
              _p.t$(
                '\
                li.worker_folder.circle_icon\
                  .icon\
                  .text Worker\
              ',
              )(),
            );
          }
        });
      } else {
        makeFakeMpMakerSize();
        // G.mp.maker.maker_el = G.mp.maker.maker_el || $1('#maker');
        _p.each($('.canvas_container'), function (el) {
          G.mp.maker.zoom.pinch_zoom(el);
        });
        await go(getCurrentFcanvas(), (f_canvas) => f_canvas.preview && addCvPreview(f_canvas));
        return _p.go(NewMakerLegacyF.swiperInit(), function () {
          $.remove_class([$1('html'), $1('body')], 'mp_maker_not_ready');
        });
      }
    }),
  );
}

function renderIsNotDesignsToCanvasA(bpcf, base_product_face, size_face, f_canvas) {
  return _p.go(
    undefined,
    () => makeCvBpcfInMakerA(f_canvas, bpcf, size_face),
    () => makeCvMask1InMakerA(f_canvas, bpcf.mask_url, size_face),
    () => makeCvMask2InMakerA(f_canvas, bpcf.mask2_url, size_face),
    () => makeCvShadingInMakerA(f_canvas, base_product_face.shading_url, size_face),
    () => makeCvSafeAreaInMakerA(f_canvas, base_product_face.safe_area_url, size_face),
    () => makeCvPrintAreaInMaker(f_canvas, size_face),
    () => makeCvPrintAreaMaskInMaker(f_canvas, size_face),
    () => NewMakerCvObjectCvSafetyArea.add(f_canvas, size_face.safety),
    () => isCylinderStyle(f_canvas) && makeCylinderAreaInMaker(f_canvas),
    () => isCompositeStyle(f_canvas) && makeCvMockupInMaker(f_canvas),
    () => addCvObjAlignmentToFcanvas(f_canvas, size_face),
    () =>
      NewMakerPropertyBpfF.bpOptionLayers.addOrDeleteCvObj(
        f_canvas,
        base_product_face[NewMakerPropertyBpfF.bpOptionLayers.cv_type],
        size_face,
      ),
    () => orderFaceBackgroundInMaker(f_canvas),
    () => {
      const { print, px_per_1cm, dpi: real_dpi } = size_face;
      const dpi = 150;
      if (base_product_face.outline_real_width_mm) {
        const cm = base_product_face.outline_real_width_mm * 0.1;
        const inch = cm / 2.54;
        const px_width = bpcf.mask2_url ? print.px.width - 90 : print.px.width;
        f_canvas._print_ratio = (inch * dpi) / px_width;
        f_canvas._px_per_1cm = px_width / cm;
      } else {
        f_canvas._print_ratio = NewMakerCheckerF.getPrintRatio({ print, dpi });
        f_canvas._px_per_1cm = px_per_1cm;
        f_canvas._dpi = dpi;
        f_canvas._real_dpi = real_dpi || dpi;
      }
    },
    () => f_canvas.renderAll(),
  );
}
